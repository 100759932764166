import React, { useContext, useState, useEffect, useRef } from 'react'
import { MainContext } from 'src/Context';
import { Table, Button, Select, DatePicker } from 'antd';
import { allinPut, removeDuplicateObj, allinOne, sortNumAlpObj, sortNumAlp } from "src/utils/functions/filterFunction"
// import { sortNumAlpObj } from "src/utils/functions/filterFunction"
import { dateTimeFormatter } from "src/utils/functions/formater"
// import { dateTimeFormatter } from "src/utils/functions/core"
import Spinner from "src/layouts/components/spinner"
import { pagination } from "src/utils/functions/specStyle"
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  weekStart: 1
})


const UserHistory = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const { lang, assetId } = context.state
  const [events, setEvents] = useState(<Spinner />)
  const [users, setUsers] = useState([])
  const [user, setUser] = useState()
  const [date, setDate] = useState()
  const [dataTable, setDataTable] = useState([])
  const [_dataTable, _setDataTable] = useState([])
  const { style } = context

  const getData = async () => {
    if (mounted.current) {
      const id = props?.item?._id
      const result = await allinOne("getDocument", { type: "Operation", id })
      // console.log(result)
      if (result.success) {
        return result?.r
      }
    }
  }

  const f = async () => {
    const d = await getData()
    if (d) {
      const workers = d?.param?.workers.map(wh => {
        const history = wh?.workHistory.map((el) => ({ ...el, labelUser: wh?.labelUser, userId: wh?.userId, value: wh?.userId, label: wh?.labelUser, startDateF: dateTimeFormatter("date", el?.startDate, "dd.mm.yyyy hh:mm") }))
        return history
      })

      const _workers = workers.flat()
      const __workers = _workers.map((el, i) => ({ ...el, key: i + 1, }))
      const users = removeDuplicateObj(__workers, "userId")
      setUsers(users)
      setDataTable(__workers)
      _setDataTable(__workers)
    }
  }

  useEffect(() => {
    f()
  }, [])

  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key',
      width: 10,
      sorter: (a, b) => sortNumAlp(a, b, "key"),
    },
    {
      title: lang?.["name"] ?? "name",
      dataIndex: 'labelUser',
      key: 'labelUser',
      // width: 30,
      // align: "center",
      sorter: (a, b) => sortNumAlpObj(a, b, "labelUser"),
      render: (text, record) => <span>{text}</span>,
    },
    {
      title: lang?.["plannedStartDate"] ?? "startDate",
      dataIndex: 'startDate',
      key: 'startDate',
      width: 200,
      // align: "center",
      sorter: (a, b) => sortNumAlp(a, b, "startDate"),
      render: (text, record) => <span>{text ? dateTimeFormatter("date", text, "dd.mm.yyyy hh:mm") : "/"}</span>,
    },
    {
      title: lang?.["duration"] ?? "duration",
      dataIndex: 'duration',
      key: 'duration',
      width: 190,
      // align: "center",     
      sorter: (a, b) => sortNumAlp(a, b, "duration"),
      render: (text, record) => {
        // const t = record?.operationKpis?.waitTime?.duration
        // const now = Date.now()
        // const dur = (record?.operationTimes?.Planned && !record?.operationTimes?.Planned?.endDate) ? Math.round((now - record?.operationTimes?.Planned?.startDate) / 1000) : 0
        // const duration = record.operationKpis?.waitTime?.duration ? record.operationKpis?.waitTime?.duration + dur : 0 + dur
        const duration = record?.duration
        // return <span>{dateTimeFormatter("duration", (t ? (t) : 0), "hh:mm")}</span>
        return <span>{dateTimeFormatter("duration", (duration ? (duration) : 0), "h")}</span>
      },
      // render: (text, record) => <span>{text}</span>,
    },
    {
      title: lang?.["endDate"] ?? "endDate",
      dataIndex: 'endDate',
      key: 'endDate',
      width: 200,
      // align: "center",
      sorter: (a, b) => sortNumAlp(a, b, "endDate"),
      render: (text, record) => <span>{text ? dateTimeFormatter("date", text, "dd.mm.yyyy hh:mm") : "/"}</span>,
    },
  ]
  const tableProps = {
    pagination
  }

  const onChange = (value, selected) => {
    setUser(selected)
  };

  const search = () => {
    if (user) {
      const _users = [..._dataTable].filter(el => el?.userId === user?.userId)
      if (date) {
        const workers = _users.filter(el => el?.startDateF.includes(date))
        const __workers = workers.map((el, i) => ({ ...el, key: i + 1, }))
        setDataTable(__workers)
      } else {
        const __workers = _users.map((el, i) => ({ ...el, key: i + 1, }))
        setDataTable(__workers)
      }
    } else {
      if (date) {
        const workers = [..._dataTable].filter(el => el?.startDateF.includes(date))
        const __workers = workers.map((el, i) => ({ ...el, key: i + 1, }))
        setDataTable(__workers)
      } else {
        setDataTable(_dataTable)
      }
    }
  };

  const onChangeDate = (date, dateString) => {
    if (dateString.length === 0) {
      setDate(undefined)
    } else {
      setDate(dateString)
    }
  };

  return (
    <div style={style?.OperationManipulateMo}>
      <div style={{ marginBottom: 10 }}>
        <Select
          style={{
            width: 300,
            borderRadius: 0
          }}
          showSearch
          onChange={onChange}
          //onSearch={onSearch}
          allowClear
          options={users} />

        <DatePicker
          onChange={onChangeDate}
          style={{
            width: 150,
            marginLeft: 10,
            borderRadius: 0
          }}
          format={'D.M.YYYY'}
          allowClear />
        <Button
          style={{
            marginLeft: 10
          }}
          ghost={false}
          type="primary"
          onClick={() => search()}>
          {lang?.["search"] ?? "search"}
        </Button>
      </div>
      <Table
        // components={{
        //   body: {
        //     cell: EditableCell,
        //   },
        // }}
        bordered
        dataSource={dataTable}
        columns={columns}
        rowClassName="editable-row"
        pagination={{
          ...tableProps?.pagination,
        }}
      />
    </div>
  )
}

export default UserHistory;
