/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useState, useEffect, useRef } from 'react'
import { Input, Button, Steps, Divider, message, Modal } from 'antd';
import { Row, Col } from 'react-bootstrap';
import { withRouter, useLocation } from "react-router-dom";
import { MainContext } from 'src/Context';
import { allinOne, checkPromisStatus, renderIconV2, calculateSum, allinPut, msg, destructUrl, urlString, allinPost } from "src/utils/functions/filterFunction"
import { dateTimeFormatter } from "src/utils/functions/formater"
import { TitleInfo, Infos, InfoColumns, EditorInfo } from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { colorPrio, colorCircle } from "src/layouts/widgets/CustomLayout/index"
import { LoadingOutlined } from '@ant-design/icons';
import { content, isLocal } from "src/utils/functions/core"
import { socket } from 'src/utils/socket';
import InfoText from 'src/layouts/widgets/Companies/i4c_V2/components/Generic/InfoText';
import { prepareInfo, prepareColumns, paramDestruct, checkEmptyValues, dragLimitCatch, statusHandler, statusCondition } from "src/layouts/widgets/Companies/i4c_V2/components/Generic/worker"
import * as comp from 'src/layouts/widgets/Companies/i4c_V2/components/layouts'
import { mainButtonStyle } from 'src/layouts/widgets/Companies/i4c_V2/constants/componentStyle';
import { buttonStyle } from 'src/layouts/widgets/Companies/i4c_V2/constants/componentStyle';
const { TextArea } = Input;

const getStatusBoard = (board, operation, lang) => {
  const items = board.map((el, i) => {
    const filterStatus = operation?.param?.statusHistory?.filter((f) => f._id === el.id)
    return filterStatus ? ({ title: (lang?.[el.name] ?? el.name), description: dateTimeFormatter("duration", calculateSum(filterStatus, "duration"), "dd:hh:mm") }) : []
  })
  const getStatus = board.find((el) => el.id === operation?.param?.status?._id)
  const colorStatus = getStatus?.kpi?.color
  const activeKey = (getStatus?.key - 1)
  return { getStatus, colorStatus, activeKey, items }
}

const OperationManipulateOp = (props) => {
  const mounted = useRef(true);
  const context = useContext(MainContext);
  const { user } = content()
  const { lang, secFormat, assetId } = context.state
  const { style } = context
  const [spinner, setSpinner] = useState(false)
  const [newData, setNewData] = useState(false)
  const [ modalUserResponse, setModalUserResponse ] = useState(false)
  const [state, setState] = useState()

  const getData = async () => {
    if (mounted.current) {
      const p = props.item?.param
      const result = await allinOne("prepareDrawerOperation", {
        id: props?.item?._id,
        productId: p?.productId,
        workOrderId: p?.workOrderId,
        businessOrderId: p?.businessOrderId,
        toolId: p?.operation?.tool?._id,
        OrderNo: p?.erp_OrderNo,
        OpNo: p?.erp_opc_OpNo
      })
      // console.log(result)
      if (result.success) {
        const statusBoard = await getStatusBoard(props?.board, result?.operation, lang)
        const cols = props?.children?.tabs?.[props?.tabName]
        result.operation.param.operationNameShort = result?.operation?.param?.operation?.operationName
        result.operation.param.nextOperationNameShort = result?.operation?.param?.nextOperation?.operationName
        result.operation.param.productName = result?.product?.selectedProduct?.product

        result.operation.param.projectCode = (result?.businessOrder?.param?.orderNumber + " " + result?.businessOrder?.param?.name)
        result.operation.param.projectManagerShort = result?.businessOrder?.param?.person?.label
        result.operation.param.deliveryStatusBO = result?.businessOrder?.param?.deliveryStatus?.label
        result.operation.param.statusNameBO = result?.businessOrder?.param?.status?.statusName
        result.operation.param.WOCode = result?.workOrder?.param?.name
        result.operation.param.statusNameWO = result?.workOrder?.param?.deliveryStatus?.label
        result.operation.param.deliveryStatusWO = result?.workOrder?.param?.status?.statusName
        result.operation.param.WOPerson = result?.workOrder?.param?.person?.label



        // console.log(result?.operation?.param)
        const columns = prepareColumns(cols, result?.operation?.param, lang, 'InfoText')
        return { ...result, ...statusBoard, columns, board: props?.board }
      }
    }
  }

  const f = async () => {
    const d = await getData()
    if (d && mounted.current) {
      setState(d)
    }
  }

  useEffect(() => {
    f()
    return () => {
      mounted.current = false
      socket.off("sentToTopic");
      //socket.off("usersOnV2");
    }
  }, [])

  const n = async () => {
    const statusBoard = await getStatusBoard(state?.board, state?.operation, lang)
    const cols = props?.children?.tabs?.[props?.tabName]
    const columns = prepareColumns(cols, state?.operation?.param, lang, 'InfoText')
    setState({
      ...state,
      ...statusBoard,
      columns,
      trigger: false
    })
  }

  useEffect(() => {
    if (state?.trigger) {
      n()

    }
  }, [state?.trigger])

  const left = () => {
    const findStatus = state?.board?.find((el) => (el.key === (state?.getStatus?.key + 1)))
    if (findStatus) {
      return state?.pauseStatus ? lang["onPause"] ? lang["onPause"] : "You are on pause" : findStatus.stepAction
    } else {
      return (lang?.["Finished"] ?? "Finished")
    }
  }

  const checkFinishStatus = () => {
    const findStatus = state?.board?.find((el) => (el.key === (state?.getStatus?.key + 1)))
    if (findStatus) {
      return false
    } else {
      return true
    }
  }

  // const _userResponse = async() => {
  //   setModalUserResponse(true)
  //   return new Promise((res) => setTimeout(() => res(true), 5000));
  // }

  const showModal = () => {
    setModalUserResponse(true);
  };
  const handleOk = () => {
    setModalUserResponse(false);
  };
  const handleCancel = () => {
    setModalUserResponse(false);
  };

  // useEffect(() => {
  //
  // }, [])
  const { confirm } = Modal;

  const onUserResponse = (e) => {
    // console.log(e)
    if (e?.type === "PartiallyFinished") {
      handleTiming("nextStatus", true)
    }else if (e?.type === "Finished") {
      handleTiming("nextStatus", false)
    }
    Modal.destroyAll();
  };

  const showConfirm = async(e) => {
    const board = state?.board
    const currentStatus = await board.find((el) => (el.key === (state?.getStatus.key)))
    const currentStatusId = currentStatus?.id
    const startProductionId = props?.children?.startProductionId
    // console.log({
    //   e,
    //   currentStatusId,
    //   startProductionId,
    //   iAgree: state?.operation?.item.param.iAgree
    // })
    // console.log(state?.operation)
    const iAgree = true //state?.operation?.param?.iAgree
    const styleButton = {
      // borderRadius: 0,
      // width: 300,
      height: 50,
      width: 200,
      fontSize: 20
      // margin: 0
      // fontSize: 35
    }
    // console.log(iAgree)

      if (currentStatusId === startProductionId) {
        const item = state?.operation
        const c = await import("./UserResponse")
        const n = await import("src/layouts/widgets/Companies/i4c_V2/components/Generic/NoIcon")
        const Component = c?.default
        const NoIcon = n?.default
        if (iAgree) {

          confirm({
            icon: <NoIcon />,
          style: {
            // display: "flex"
            minWidth: "fit-content"
          },
          width: "40%",
          content: <Component
          onClick={onUserResponse}
          lang={lang}
          style={{
            ...buttonStyle,
            width: "auto",
            float: "left",
            marginRight: 20,
            position: "relative",
            // left: "50%",
            // transform: "translate(-50%)"
          }}
          />,
        cancelButtonProps: { type: "primary", danger: true, ghost: false },
        cancelText: lang?.["close"] ?? "close",
        // cancelButtonProps: { style: { display: 'none', marginTop: 0 } },
        okButtonProps: { style: { display: 'none', marginTop: 0 } },
        // content: <Button onClick={destroyAll}>Click to destroy all</Button>,
        onOk() {
          handleTiming("nextStatus")
        },
        onCancel() {
          console.log('Cancel');
        }})
        }else {
          msg("warning", (lang?.["mustIAgree"] ?? "mustIAgree"), 2)
        }
        // const _statusCondition = await statusCondition(item)
        // if (_statusCondition) {
        // }else {
        //   // if goodItems are >= planned amount
        //   handleTiming("nextStatus")
        //   // console.log("go to finished")
        // }
    }else {
      handleTiming("nextStatus")
      }

  }


  const pauseOperation = (e) => {
    console.log(e)
  }

  const handleTiming = async (type, e) => {
    // console.log(type, e)
    let _statusHistory = []
    const item = state?.operation
    const board = state?.board
    const getStatus = state?.getStatus
    const now = Date.now()
    const currentStatus = board.find((el) => (el.key === (state?.getStatus.key)))
    const findPrevtStatus = board.find((el) => (el.key === (state?.getStatus.key - 1)))
    const findNextStatus = board.find((el) => (el.key === (state?.getStatus.key + 1)))
    const findLastStatus = board.find((el) => el.id === item?.param?.status?._id)
    const findNextStatusParameters = board.find((el) => (el.key === (Number(item?.param?.status) + 2)))
    const destination = {
      _i: 0,
      _id: findNextStatus.id
    }
    const _user = {
      _id: user.userId,
      user: user.firstName + " " + user.lastName
    }
    _statusHistory.push({
      startDate: now,
      _id: findNextStatus.id,
      name: findNextStatus.name,
      user: _user,
      statusType: findNextStatus.kpi.value,
      duration: 0
    })
    item.param?.statusHistory.forEach((el, i) => {
      _statusHistory.push({
        ...el,
        endDate: (i === 0) ? now : (el.endDate ? el.endDate : 0),
        duration: (i === 0) ? secFormat(now - el.startDate) : el.duration,
        // user: _user
      })
    });
    const s = _statusHistory.reduce((acc, curr) => acc + curr.duration, 0)
    const statusId = findNextStatus?.id
    const startPreperationId = props?.children?.startPreperationId
    const startProductionId = props?.children?.startProductionId
    const stopProductionId = props?.children?.stopProductionId
    const actualSetupStartDate = item?.param?.actualSetupStartDate
    const actualProductionStartDate = item?.param?.actualProductionStartDate
    const actualSetupEndDate = item?.param?.actualSetupEndDate
    const actualProductionEndDate = item?.param?.actualProductionEndDate
    const setupDuration = item?.param?.setupDuration
    const productionDuration = item?.param?.productionDuration
    const plannedDuration = item?.param?.plannedDuration
    const plannedProductionStartDate = item?.param?.plannedProductionStartDate
    const plannedProductionEndDate = item?.param?.plannedProductionEndDate
    const _actualSetupStartDate = (statusId === startPreperationId) ? now : (actualSetupStartDate ? actualSetupStartDate : null)
    const _actualSetupEndDate = (statusId === startProductionId) ? now : (actualSetupEndDate ? actualSetupEndDate : null)
    const _actualProductionStartDate = (statusId === startProductionId) ? now : (actualProductionStartDate ? actualProductionStartDate : null)
    const _actualProductionEndDate = (statusId === stopProductionId) ? now : (actualProductionEndDate ? actualProductionEndDate : null)
    // console.log(item)

    const round = value => {
      return (Math.floor(Number(value) / 1000))
    }

    const newParam = {
      // stupid solution
      ...item?.param,
      endDate: (board.length === findLastStatus.key + 1) ? now : null,
      status: destination,
      statusHistory: _statusHistory,
      duration: s,
      // previousOperation: currentStatus?.name,
      // duration
      setupDuration: setupDuration ? setupDuration : (_actualSetupEndDate && _actualSetupStartDate) ? round(_actualSetupEndDate - _actualSetupStartDate) : null,
      // actualDuration: actualDuration ? actualDuration : (_actualProductionEndDate && _actualProductionStartDate) ? (_actualProductionEndDate - _actualProductionStartDate) : null,
      productionDuration: productionDuration ? productionDuration : (_actualProductionEndDate && _actualProductionStartDate) ? round(_actualProductionEndDate - _actualProductionStartDate) : null,
      // actual setup
      actualSetupStartDate: _actualSetupStartDate,
      actualSetupEndDate: _actualSetupEndDate,
      // actual production
      actualProductionStartDate: _actualProductionStartDate,
      actualProductionEndDate: _actualProductionEndDate,
    }
    if (e) {
      newParam.PartiallyFinished = e
    }else {
      newParam.PartiallyFinished = false
    }

    const goodItems = newParam?.goodItems
    // const con = checkEmptyValues(newParam, stopProductionId, statusId)
    // if (con) {
    //   return msg("warning", (lang?.["noItemsProduced"] ?? "noItemsProduced"), 2)
    // }
    const startPlannedId = props?.children?.startPlannedId
    // const materialStatus = item?.param?.materialStatus === "ready"
    const checkPlanned = ((item?.param?.materialStatus === "notReady") && (startPlannedId === findNextStatus?.id)) ||
      (item?.param?.materialStatus === "inPreparation") ||
      (item?.param?.materialStatus === "ready")
    const statuses = []
    const dragLimit = dragLimitCatch(findNextStatus)
    const productionStatus = board.find((f) => f?.id === startProductionId)
    const dragLimitFilter = (productionStatus?.items?.length < productionStatus?.limit)
    const pass = statusHandler({
      lang,
      operation: item,
      props,
      board,
      prevId: findPrevtStatus?.id,
      sourceId: currentStatus?.id,
      destinationId: findNextStatus?.id
    })
    // console.log({
    //   e,
    //   pass,
    //   newParam
    //   // waitResponse
    // })

    // if (userRespose) {
    //   console.log("ok")
    // }
    // const waitResponse = await _userResponse()
    // const waitResponse = await allinOne("userResponse", {})
    // return false
    if (pass) {
    // if (newParam && checkPlanned && dragLimit) {
      const newItem = { ...item, param: newParam }
      const getStatus = board?.find((el) => el.id === newItem?.param?.status?._id)
      const kpis = board?.map((el) => (el?.kpi))
      const colorStatus = getStatus?.kpi?.color
      const activeKey = (getStatus?.key - 1)
      const items = board.map((el, i) => {
        const filterStatus = newItem?.param?.statusHistory.filter((f) => f._id === el.id)
        return ({ title: el.name, description: dateTimeFormatter("duration", calculateSum(filterStatus, "duration"), "dd:hh:mm") })
      })
      const finishStatusId = props?.children?.finishStatusId
      const _children = {
        eventConfigType: props?.children?.eventConfigType,
        finishStatusId: props?.children?.finishStatusId,
        startPlannedId: props?.children?.startPlannedId,
        startPreperationId: props?.children?.startPreperationId,
        startProductionId: props?.children?.startProductionId,
        stopProductionId: props?.children?.stopProductionId,
      }

      setState({
        ...state,
        operation: newItem,
        getStatus,
        colorStatus,
        activeKey,
        trigger: true
      })
      const result = await allinPost("UpdateItemParamStatus", {
        assetId,
        kpis,
        id: item._id,
        param: newParam,
        // topic: "teeeeest",
        nextOperation: item?.param?.nextOperation,
        currentStatus: {
          ...currentStatus,
          items: []
        },
        findNextStatus: {
          ...findNextStatus,
          items: []
        },
        finishStatusId,
        children: _children,
        board: board.map((el) => ({ id: el?.id, kpi: el?.kpi, name: el?.name })) // send only necesary values
      })

      if (result.success) {
        item.param = newParam
        item.param.workers = result?._updateUserTime?.workers
        setState({
          ...state,
          operation: item,
          getStatus,
          colorStatus,
          activeKey,
          trigger: true
        })
        // socket.connect();
        socket.emit("usersOnV2", true)
        socket.emit("sentToTopic", {
          topic: "operationStatus",
          trigger: true,
          data: "items",
          exceptions: "operationStatus"
        })
        const s = destructUrl(window.location.search)
        if (s?.search) {
          const _urlParam = {
            ...destructUrl(window.location.search),
            tr: Date.now()
          }
          const search = urlString(_urlParam)
          props.history.push({ search })
        }
      }
    } else {
      // msg("warning", (lang?.["notReady"] ?? "notReady"), 3)
    }
  }

  const _item = { name: state?.operation?.param?.operation?.operationName }
  // console.log(mainButtonStyle)
  // console.log(left())
  const _widthCol = (100 / state?.columns?.length).toString()
  const widthCol = _widthCol + "%"

  return (
    <>
      <div style={style.OperationManipulateMo}>
        {/*
      <TitleInfo key={JSON.stringify(_item)} styles={props?.children?.styles?.TitleInfo} dataProps={_item} />
      <Divider />
      */}
        <Steps
          // key={JSON.stringify(state?.items)}
          style={style.OperationManipulateMo.Steps}
          // type="navigation"
          // size="small"
          // className="OperationManipulateMoSteps"
          current={state?.activeKey}
          items={state?.items}
        />
        <Divider />
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <div style={{ flexGrow: 1 }}>
            <Row style={{ overflow: "hidden", textOverflow: "ellipsis", minWidth: 400, width: "100%" }}>
              {state?.columns.map((el, i) => {
                const Component = comp[comp[el?.comp] ? el?.comp : "Empty"]
                return (
                  <Col key={i} style={{ padding: 0, overflow: "hidden", textOverflow: "ellipsis", marginRight: 5, minWidth: 400 }}>
                    <Component key={JSON.stringify(el?.info)} dataProps={el?.info} />
                  </Col>
                )
              })}
              <Col style={{ padding: 0, overflow: "hidden", textOverflow: "ellipsis", marginRight: 5, minWidth: 400, maxWidth: widthCol }}>
                <Button
                  // key={JSON.stringify(item)}
                  ghost={checkFinishStatus()}
                  style={{ ...mainButtonStyle, background: state?.colorStatus, position: "relative", right: 0 }}
                  type="primary"
                  // onClick={() => handleTiming("nextStatus")}
                  onClick={showConfirm}
                  disabled={checkFinishStatus()}>
                  {(spinner || !state?.board) ? <LoadingOutlined style={{ fontSize: 24 }} spin /> : (lang?.[left()] ?? left())}
                </Button>
                {/*
                <Button
                  ghost={checkFinishStatus()}
                  style={{ ...mainButtonStyle, width: "50%", position: "relative", right: 0 }}
                  type="primary"
                  onClick={pauseOperation}
                  disabled={checkFinishStatus()}>
                  {lang?.["pauseOperation"] ?? "pauseOperation"}
                </Button>
                */}
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}

export default withRouter(OperationManipulateOp);
