/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { withRouter } from "react-router-dom";
import { MainContext } from 'src/Context';
// import { Row, Col } from "react-bootstrap";
import { Button, Tooltip, Badge, notification } from 'antd';
import { UserSwitchOutlined, HomeOutlined, SettingOutlined, LoadingOutlined } from '@ant-design/icons';
import { limitText, allinOne } from "src/utils/functions/filterFunction"
// import Spinner from '../../components/Spin';
import { content } from "src/utils/functions/core"
// import { BsFullscreen } from 'react-icons/bs';
import { socket } from 'src/utils/socket';
// import { spining } from 'src/layouts/widgets/CustomLayout';
// import { Clock } from '../../layouts';

// import { RiArrowRightSFill } from 'react-icons-ng/ri';
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';

import SpinnerCustom from '../../components/SpinnerCustom';

const ButtonLogout = () => {
  return (
    <div>
      Please logout and login again or refresh page
      <Button type="primary" danger={true} onClick={() => window.location.reload()}>
        Logout
      </Button>
    </div>
  )
}

class Header extends React.Component {
  static contextType = MainContext;
  _isMounted = false;
  constructor(props) {
    super(props)
    this.state = { count: false, ModalUsersOn: false, modalOpen: false }
  }

  detectMob = () => {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i
    ];
    return toMatch.some((toMatchItem) => {
      return navigator.userAgent.match(toMatchItem);
    });
  }

  getUsers = (assets) => {
    const { user } = content()
    const { assetId } = this.context.state
    // const { ModalUsersOn } = this.state
    const asset = assets?.[assetId]
    const _user = asset ? asset.find((f) => f?.userId === user?.userId) : false
    // console.log({
    //   assets,
    //   assetId,
    //   asset,
    //   user,
    //   _user
    // })

    this.setState({
      count: _user ? (asset?.length) : 0,
      // ModalUsersOn: ModalUsersOn
    })
  }

  f = () => {
    setTimeout(async () => {
      const result = await allinOne("getSpecDocument", {})
      const assets = result?.assets
      this.getUsers(assets)
      // this.setState({ count: Number(count) })
    }, 2000)
  }

  componentDidMount = () => {
    // console.log(this.detectMob())
    if (this.detectMob()) {
      // require('../../../assets/css/allMobile.css');
    } else {
      require('../../../assets/css/all.css');
    }
    const { userConfig, licenseIndex, lang, assetId } = this.context.state
    this.f()
    // socket.connect()
    // socket.on('usersOn', (el) => {
    //   const asset = el?.[assetId]
    //   this.getUsers(el)
      // if (!asset) {
      //   this.setState({ ModalUsersOn: false }, () => {
      //     // this.getUsers(el)
      //     // console.log({
      //     //   el,
      //     //   assetId,
      //     //   asset
      //     // })
      //   })
      // }
      // const keys = Object.keys(el)
      // let users = []
      // for (var key of keys) {
      //   const _asset = el?.[key]
      //   for (var _users of _asset) {
      //     users.push(_users)
      //   }
      // }
      // const count = users?.length
      // // console.log(count)
      // this.setState({ count })
    // })
    // socket.on('usersOnV2', (el) => {
    //   // const asset = el?.[assetId]
    //   this.f()
    // })
    // socket.on('lstClear', (el) => {
    //   if (el?.userId) {
    //     notification.warning({
    //       message: 'New version',
    //       description: <ButtonLogout />,
    //       duration: null,
    //       closeIcon: false
    //     })
    //     localStorage.clear()
    //   }
    // })
    if (userConfig) { this.licenceToasify(lang, userConfig.user, licenseIndex) }
  }

  componentWillUnmount() {
    this.setState({ ModalUsersOn: false })
    // socket.off('lstClear')
  }
  componentDidUpdate() {
    const { headerModal } = this.context.state
    if (headerModal) {
      this.setState({
        ModalUsersOn: false
      }, this.context.contextCall("headerModal", false))
    }
    // console.log(modalUserOnContext)
  }

  handleButton = (x, path) => {
    this.props.history.push(path)
  }

  licenceToasify = (lang, user, licenseIndex) => {
    const { style } = this.context.state
    const daysRemain = parseInt(user.licenseUser.daysRemain);
    if (daysRemain <= 14) {
      return (
        <div style={style.header.license}>
          {"Your licence expire in "}
          <span style={{ color: "red" }}> {daysRemain} </span>
          {lang[" days"]}, {"Please contact support"}
          <p></p>
        </div>
      )
    }
  }

  logoutUser = () => {
    const { queryPut, authToken, userConfig } = this.context.state
    queryPut({
      url: "/userTrackUpdate",
      token: authToken,
      type: "UserTrack",
      id: userConfig.user.userId,
      // keys: "i4c.*",
    }).then(res => {
      if (res) {
        socket.removeAllListeners()
        socket.disconnect();
        this.context.logOut(true)
      }
    })
  }

  handleUsersOn = async () => {
    const { _assetGlobal } = this.context.state
    const assetId = _assetGlobal?._id
    if (assetId) {
      const c = await import("src/layouts/widgets/Companies/i4c_V2/main/UsersOn");
      const Comp = c?.default
      this.setState({ ModalUsersOn: Comp, modalOpen: true })
    } else {
      this.logoutUser()
    }
  }

  closeModal = () => {
    this.setState({ ModalUsersOn: false })
  }

  render() {
    const { lang, userConfig, homeRoute, layoutDataContext, pathNameActive, licenseIndex, showHeaderFooter, spinnerLoad, spinnerCustom, _assetGlobal } = this.context.state
    const { style, modalUserOnContext } = this.context
    const { user } = content()
    const { ModalUsersOn, count, modalOpen } = this.state
    // if (!layoutDataContext || spinnerLoad) {
    //   return(
    //     <div style={{ position: "fixed", top: "30%", left: "50%" }}>
    //       <Spinner spin={layoutDataContext} />
    //     </div>
    //   )
    // }
    const assetId = _assetGlobal?._id
    // console.log(assetId)
    const userLabel = user?.firstName + " " + user?.lastName
    // console.log(modalUserOnContext)
    const color = "#52c41a"
    return (
      <>
        <span>
          {false ?
            <>
              <span style={style.header.span}>
                {layoutDataContext ? layoutDataContext.widgets[0][0][0].company : "companyName"}
                {"\xa0"} - {userConfig.user.firstName} {userConfig.user.lastName}
                {/*{"\xa0"} - <Clock /> - {userConfig.user.firstName} {userConfig.user.lastName}*/}
              </span>
              <span style={style.header.title}><ArrowRightOutlined style={style.header.reactIcon} />{"\xa0"}{lang[pathNameActive] ? lang[pathNameActive] : pathNameActive}</span>
            </>
            : null}
        </span>
        <span>
          <Tooltip title={lang["Switch user"] ? lang["Switch user"] : "Switch user"}>
            <Button onClick={() => this.handleUsersOn()} style={{ ...style.header.button, marginRight: 20 }}>
              {/* <Badge color={color} count={count ? count : ((count === 0) ? "" : <LoadingOutlined style={{ fontSize: 24, color: "#ff1600" }} spin />)}> */}
                <UserSwitchOutlined style={{ fontSize: 25 }} />
              {/* </Badge> */}
              <div style={{ fontSize: 10 }}>
                {limitText((userLabel ? userLabel : ""), 15)}
              </div>
            </Button>
          </Tooltip>
          {window.location.pathname !== "/dashboard" ?
            <Tooltip title={lang["Home"] ? lang["Home"] : "Home"}>
              <Button onClick={() => this.handleButton(null, homeRoute)} style={style.header.button}>
                <HomeOutlined style={style.header.icon} />
              </Button>
            </Tooltip> : null}
          {window.location.pathname === "/dashboard" ?
            <Button onClick={() => this.handleButton(null, "/settings")} style={style.header.button}>
              <SettingOutlined style={style.header.icon} />
            </Button> : null}

          {/*
                  <Button onClick={() => {}} style={{ ...style.header.buttonInterrupt }}>
                  {renderIconV2("RiPauseCircleLine", 30, "")}
                  <div style={{ fontSize: 10 }}>Interrupt</div>
                  </Button>
                  <Button onClick={() => this.context.contextCall("isFull", !isFull)} style={style.header.button}>
                  <BsFullscreen />
                  </Button>
                  */}
        </span>
        {userConfig ? <span>{this.licenceToasify(lang, userConfig.user, licenseIndex)}</span> : null}

        {(ModalUsersOn && assetId) && <ModalUsersOn key={JSON.stringify(ModalUsersOn)} modalOpen={modalOpen} closeModal={this.closeModal} assetId={assetId} />}
        {spinnerCustom.spin && <SpinnerCustom data={spinnerCustom} />}
      </>
    )
  }
}

export default withRouter(Header);
