import { dateTimeFormatter } from "src/utils/functions/formater"
import { allinOne, allinPut, msg } from "src/utils/functions/filterFunction"
import { socket } from 'src/utils/socket';
import { content } from "src/utils/functions/core"

const checkValues = (type, value) => {
  if (type === "int") {
    return Number(value)
  } else if (type === "string") {
    return value
  } else if (type === "date") {
    const _value = (Number(value) === 0 || isNaN(Number(value))) ? null : Number(value)
    return dateTimeFormatter("date", _value, "dd.mm.yyyy hh:mm")
  } else if (type === "duration") {
    const _value = (Number(value) === 0 || isNaN(Number(value))) ? null : Number(value)//(Math.floor(Number(value) / 1000))
    // console.log([Number(value), _value, isNaN(Number(value))])
    return dateTimeFormatter("duration", _value, "dd:hh:mm")
  } else if (type === "array") {
    // console.log(Array.isArray(value))
    return JSON.stringify(value)
    // return dateTimeFormatter("duration", Number(value), "dd:hh:mm")
  }
}

const prepareInfo = (param, keys, col, lang) => {
  if (Array.isArray(col)) {
    const showKeys = keys.filter((f) => col.find((fi) => fi?.value === f))
    // console.log(showKeys)
    let info = []

    col.forEach((el, i) => {
      const obj = (typeof param?.[el?.value] === 'object')
      const value = (param?.[el?.value]?.label) ? (checkValues(el?.type, param?.[el?.value]?.label)) : (checkValues(el?.type, param?.[el?.value]))
      if (obj) {
        info.push({
          label: lang?.[el?.value] ?? el?.value,
          value,
          values: (el?.type === "array") ? value : false
        })
      } else {
        info.push({
          label: lang?.[el?.value] ?? el?.value,
          value: checkValues(el.type, param?.[el?.value]),
          tooltipValue: lang?.[el?.tooltipValue] ?? el?.tooltipValue,
          values: (el?.type === "array") ? JSON.stringify(param?.[el?.value]) : false
        })
      }
    });
    return info//.reverse()
  } else {
    return false
  }
}

const prepareColumns = (cols, param, lang, comp) => {
  const keys = cols ? Object.keys(cols) : false
  const objKeys = param ? Object.keys(param) : []
  let columns = []
  if (keys && objKeys) {
    keys.forEach((el, i) => {
      if (Array.isArray(cols[el])) {
        columns.push({
          key: i + 1,
          title: "",
          comp: comp ? comp : 'InfoText',
          style: { fontSize: 20 },
          info: prepareInfo(param, objKeys, cols[el], lang)
        })
      }
    });
    return columns
    // return []
  } else {
    msg("error", (lang?.["missingConfig"] ?? "missingConfig"), 2)
    return []
  }
}

const paramDestruct = (p) => {
  // console.log(p)
  // const products = p?.products
  if (p) {

    const productsAmount = p?.products.map((el) => (el.amount))
    const sum = productsAmount.reduce((a, b) => a + b, 0);
    p.quantityOrdered = (Array.isArray(productsAmount)) ? productsAmount?.[0] : 0
    // console.log(p)
    return p
  }
  // products
}

const sumValues = (e, property) => {
  return e.reduce((accumulator, object) => {
    return accumulator + object[property];
  }, 0);
}

const sliceText = (e, el) => {
  const type = el?.type
  const textLength = el?.textLength ? el?.textLength : false
  if (type === "int") {
    return (textLength && e && (e.length > textLength)) ? ("..." + e.slice(-textLength)) : e
  } else if (type === "string") {
    return (textLength && e && (e.length > textLength)) ? ("..." + e.slice(-textLength)) : e
  } else if (type === "date") {
    return dateTimeFormatter("date", Number(e), "dd.mm.yyyy hh:mm")
  } else if (type === "duration") {
    return dateTimeFormatter("duration", Number(e), "dd:hh:mm")
  } else if (type === "array") {
    const valueType = el?.valueType
    const property = el?.property
    switch (valueType) {
      case "SUM": return sumValues(e, property);
      // case "SUM": return sumValues();
      // case "SUM": return sumValues();
      // case "SUM": return sumValues();
      default: return JSON.stringify(e);
    }
    return JSON.stringify(e)
  }
}

const prepareColumn = (p, cardInfo, lang) => {
  let rows = []
  cardInfo.forEach((el, i) => {
    const t = "tooltip_" + el?.value
    const v = el?.value
    rows.push({
      label: lang?.[v] ?? v,
      tooltip: lang?.[t] ?? t,
      value: sliceText(p?.[v], el),
      tooltipValue: p?.[v],
    })
  });
  return rows
}
// {
//   "warehouseCode": "33690166",
//   "requiredQuantity": 1000,
//   "name": "1.25BQ1 600X1120"
// }
const makeAReservation = async (id, contentQuantity, content, auth_token) => {
  const prepareUpdateOperation = {
    id,
    requiredMaterials: content
  }
  const prepareUpdateMaterial = {
    code: contentQuantity?.warehouseCode, //param.code,
    reservations: []
  }
  // console.log({id, contentQuantity, content})
  const result = await allinPut("prepareForReservation", { id, contentQuantity, content }, auth_token)
  // console.log(result)
}

const checkEmptyValues = (newParam, stopProductionId, statusId) => {
  const goodItems = newParam?.goodItems
  const badItems = newParam?.badItems
  if (stopProductionId !== statusId) {
    return false
  }
  if ((goodItems > 0) || !goodItems && ((badItems > 0) || !badItems)) {
    if (goodItems > 0) {
      return false
    }
    if (badItems > 0) {
      return false
    }
    return true
  }
  return false
}

const updateUserAssetMgmt = async (e, f, call) => {
  // console.log(e, f, call)
  const result = await allinPut(call, e)
  // console.log(result)
  // const r = await allinPut("updateSpecOperation", { ...e, newData: f })
  if (result) {
    const data = result?.assets?.[e?.assetId]
    f(data)
    socket.emit("usersOn", result?.assets)
  }
  return result
}

const updateSpecOperation = async (e, f) => {
  const r = await allinPut("updateSpecOperation", e)
  return r
}

const updateSpecSelectedOperations = async (e, f) => {
  const r = await allinPut("updateSpecSelectedOperations", e)
  return r
}

const checkStatus = () => {

}

const setWfTasks = async (WorkflowCatalog, tasks) => {
  let _board = []
  if (WorkflowCatalog) {
    const status = {
      'ready': 1,
      'inPreparation': 2,
      'notReady': 3
    };
    // tasks.sort((a, b) => status[a?.param?.materialStatus] - status[b?.param?.materialStatus])
    const steps = WorkflowCatalog?.param?.steps
    for (var i = 0; i < steps.length; i++) {
      // eslint-disable-next-line no-loop-func
      const findStatus = await tasks.filter((f, j) => (f?.param?.status?._id) === steps[i].id)
      if (findStatus) { _board.push({ ...steps[i], items: findStatus }) } else { _board.push(steps[i]) }
    }
    return _board
  } else {
    msg("error", "Missing Workflow catalog", 10)
    return _board
  }
}

const dragLimitCatch = (findNextStatus) => {
// console.log({
//   findNextStatus
// })
if (findNextStatus?.dragLimit) {
  if (findNextStatus.items.length > 0) {
    return false
  }else {
    return true
  }
}else {
  return true
}
  // console.log({
  //   board, source, destination,
  //   statuses
  // })
  // if (destination.length > 0) {
  //   return false
  // }else {
  //   return false
  // }
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const statusCondition = (e, lang) => {
  const amount = e?.param?.amount
  const goodItems = e?.param?.goodItems
  if (goodItems >= amount) {
    return false
  }else {
    if (goodItems === 0) {
      // msg("warning", (lang?.["noItemsProduced"] ?? "noItemsProduced"), 3)
      return false
    }else {
      return true
      // return false
    }
  }
}

const countOp = (e) => {
  const _e = e.filter((f) => !f?.param?.onPause)
  if (_e && _e.length >= 1) {
    return false
  }else {
    return true
  }
}

const statusHandler = (e) => {
  const lang = e?.lang
  // console.log(e?.props?.children)
  const startPlannedId = e?.props?.children?.startPlannedId
  const startPreperationId = e?.props?.children?.startPreperationId
  const startProductionId = e?.props?.children?.startProductionId
  const finishStatusId = e?.props?.children?.finishStatusId
  const deliveryStatusId = e?.props?.children?.deliveryStatusId

  const prevId = e?.prevId
  const destinationId = e?.destinationId
  const sourceId = e?.sourceId
  const operation = e?.operation
  const board = e?.board

  // console.log(e)
  // "1"
  if (destinationId === startPlannedId) {
  // if (destinationId === startPlannedId && operation?.param?.materialStatus === "ready") {
  // console.log("if planned")
    return true
  // "2"
  }else if (destinationId === startPreperationId) {
    // console.log("1")
    if (operation?.param?.materialStatus === "ready") {
      // console.log("2")
      const preperationStatus = board.find((f) => f?.id === startPreperationId)
      const productionStatus = board.find((f) => f?.id === startProductionId)
      const preperationFilter = (preperationStatus?.dragLimit && (preperationStatus?.items.find((f) => f?.param?.onPause) ? (countOp(preperationStatus?.items)) : (preperationStatus?.items?.length < preperationStatus?.limit)))
      const productionFilter = (productionStatus?.dragLimit && (productionStatus?.items.find((f) => f?.param?.onPause) ? (countOp(productionStatus?.items)) : (productionStatus?.items?.length < productionStatus?.limit)))
      const groupFilter = [...preperationStatus?.items, ...productionStatus?.items]
      const _groupFilter = groupFilter.filter((f) => f?.param?.onPause)
      // console.log({})
      if ((preperationFilter && productionFilter) || ((_groupFilter && _groupFilter.length > 0) && (preperationFilter || productionFilter))) {
        // console.log("3")
        return true
      }else {
        // console.log("4")
        msg("warning", (lang?.["alreadyInPrepOrProd"] ?? "alreadyInPrepOrProd"), 3)
        return false
      }
    }else {


      const preperationStatus = board.find((f) => f?.id === startPreperationId)
      const productionStatus = board.find((f) => f?.id === startProductionId)
      const preperationFilter = (preperationStatus?.dragLimit && (preperationStatus?.items.find((f) => f?.param?.onPause) ? (countOp(preperationStatus?.items)) : (preperationStatus?.items?.length < preperationStatus?.limit)))
      const productionFilter = (productionStatus?.dragLimit && (productionStatus?.items.find((f) => f?.param?.onPause) ? (countOp(productionStatus?.items)) : (productionStatus?.items?.length < productionStatus?.limit)))
      const groupFilter = [...preperationStatus?.items, ...productionStatus?.items]
      const _groupFilter = groupFilter.filter((f) => f?.param?.onPause)
      // console.log("5")
      // console.log({})
      if ((preperationFilter && productionFilter) || ((_groupFilter && _groupFilter.length > 0) && (preperationFilter || productionFilter))) {
        // console.log("5-1")
        if (e?.operation?.param?.requiredMaterials && e?.operation?.param?.requiredMaterials.length > 0) {
          msg("warning", (lang?.["notReady"] ?? "notReady"), 3)
          // console.log("5-2")
          return false
        }else {
          // console.log("5-3")
          return true
        }
        // return true
      }else {
        // console.log("5-4")
        msg("warning", (lang?.["alreadyInPrepOrProd"] ?? "alreadyInPrepOrProd"), 3)
        return false
      }


      return false


    }
    // console.log("6")
    return "second"//dragLimitFilter
    // "3"
  }else if (destinationId === startProductionId) {
    // console.log("7")
    if (sourceId === startPreperationId) {
      // console.log("8")
      const productionStatus = board.find((f) => f?.id === startProductionId)
      const dragLimitFilter = (productionStatus?.dragLimit && (productionStatus?.items.find((f) => f?.param?.onPause) ? (countOp(productionStatus?.items)) : (productionStatus?.items?.length < productionStatus?.limit)))
      // const dragLimitFilter = (productionStatus?.dragLimit && productionStatus?.items?.length < productionStatus?.limit)
      // console.log("=> inPreparation", dragLimitFilter)
      return dragLimitFilter
    }else {
      const PartiallyFinished = e?.operation?.param?.PartiallyFinished
      // console.log("9")
      if (PartiallyFinished) {
        // console.log("10-PartiallyFinished")
        return true
      }else {
        // console.log("11")
        return false
      }
    }
  }else if (destinationId === finishStatusId) {
    // console.log("10")
    if (sourceId === startProductionId) {
      const goodItems = operation?.param?.goodItems
      const badItems = operation?.param?.badItems
      if (goodItems > 0 || badItems) {
        // console.log("11")
        return true
      }else {
        // msg("warning", (lang?.["noItemsProduced"] ?? "noItemsProduced"), 3)
        // console.log("12")
        // console.log(" no items")
        return true
        // return false
      }
      // return true
      // console.log({
      //   t: "=> finished",
      // })
    }else {
      // console.log("13")
      return false
    }
  }
const status = board.find((f) => (f?.id === sourceId))
// console.log(status)
if (status?.allowBackStep || status?.allowForwardStep) {
  // console.log("14")
    return true
}else {
  // console.log("14")
  return false
}
  // const preperationStatus = board.find((f) => f?.id === startPreperationId)
}

const eventTriggerCatch = (findNextStatus) => {
  // console.log({
  //   line: "245-app",
  //   findNextStatus
  // })
  return findNextStatus?.eventTrigger
  // if (findNextStatus?.eventTrigger) {
  //   if (findNextStatus.items.length > 0) {
  //     return false
  //   }else {
  //     return true
  //   }
  // }else {
  //   return true
  // }
}

const move = (source, destination, droppableSource, droppableDestination, draggableId, board, props, lang) => {
  const _droppableId = droppableDestination?.droppableId
  const findPrevtStatus = board.find((el) => (el.key === (Number(_droppableId) - 1)))
  const findNextStatus = board.find((el) => (el.key === (Number(_droppableId) + 1)))
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);
  destClone.splice(droppableDestination.index, 0, removed);
  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;
  const startPlannedId = props?.children?.startPlannedId
  const startPreperationId = props?.children?.startPreperationId
  const startProductionId = props?.children?.startProductionId
  const finishStatusId = props?.children?.finishStatusId
  const deliveryStatusId = props?.children?.deliveryStatusId
  const statuses = []

  const droppableSourceId = board?.[Number(droppableSource?.droppableId)]?.id
  const productionStatus = board.find((f) => f?.id === startProductionId)
  const dragLimitFilter = (productionStatus?.items?.length < productionStatus?.limit)

  const dragLimit = dragLimitCatch(findNextStatus)

  // return false
  // dragLimitCatch(findNextStatus, board, source, destination, statuses)
  const nextStatusId = findNextStatus?.id
  const checkPlanned =
  ((removed?.param?.materialStatus === "notReady") && (nextStatusId === startPlannedId))
  ||
  (removed?.param?.materialStatus === "ready")

  const pass = statusHandler({
    lang,
    operation: removed,
    props,
    board,
    prevId: findPrevtStatus?.id,
    sourceId: droppableSourceId,
    destinationId: nextStatusId
  })

  // NOTE: eventTrigger ONLY IF PASS
  // const eventTrigger = eventTriggerCatch(findNextStatus)
  // console.log(eventTrigger)
  // console.log(pass)
  // console.log()
  // if (false) {
  // console.log(pass)
  if (pass) {
  // if (checkPlanned && dragLimit) {
    return result;
  } else {
    // msg("warning", (lang?.["notReady"] ?? "notReady"), 3)
    return false;
  }
};

const statusName = (statusName) => {
  switch (statusName) {
    case "new" : return "eea744d7-0c70-4b92-a6a6-587008f19145";
    case "planned": return "a241e538-78cb-405c-a76d-aa01b67a2928";
    case "Preparation": return "46babb43-3bfe-4e32-8e9b-54a1edc1ac09";
    case "InProduction": return "a241e538-78cb-405c-a76d-aa01b67a2928";
    case "Finished": return "a241e538-78cb-405c-a76d-aa01b67a2928";
    default: return null
  }
}

const reverseStatusName = (statusOpId) => {
  switch (statusOpId) {
    case "eea744d7-0c70-4b92-a6a6-587008f19145": return "new";
    case "a241e538-78cb-405c-a76d-aa01b67a2928": return "planned";
    case "46babb43-3bfe-4e32-8e9b-54a1edc1ac09": return "Preparation";
    case "11a79782-b79c-4562-9e9e-c00c3237dccc": return "InProduction";
    case "bad71241-1d82-4774-8342-b593087f35cb": return "Finished";
    default: return null
  }
}

const setNewStatusOp = async(e) => {
  // NOTE:
  // func need
  // - assetId
  // - current status (statusId)
  // - operation id (_id)
  // - lang (language)
  // "sample"
  // const obj = {
  //   assetId,
  //   lang,
  //   statusId: currentStatus,
  //   _id: operation?._id,
  // }
  const { user } = content()
  const operationId = e?._id
  const statusId = e?.statusId
  const assetId = e?.assetId
  const lang = e?.lang
  const workflowId = "6274aa0f-af4d-47ad-a4f0-9e10230c8ef4"
  const obj = {
    operationId,
    statusId,
    assetId,
    workflowId,
    user
  }
  // const _user = {
  //   _id: user.userId,
  //   user: user.firstName + " " + user.lastName
  // }
  // console.log(obj)
  const result = await allinPut("prepareOpBoard", obj);
  // console.log(result)
  if (result?.success) {
    if (result?.msg === "notAllowed") {
      msg("warning", (lang?.[result?.msg] ?? result?.msg), 2)
      return false
    }
    return result
  }
}

const setNewStatusOpV2 = async(e) => {
  // NOTE:
  // func need
  // - assetId
  // - current status (statusId)
  // - operation id (_id)
  // - lang (language)
  // "sample"
  // const obj = {
  //   assetId,
  //   lang,
  //   statusId: currentStatus,
  //   _id: operation?._id,
  // }
  const { user } = content()
  const operationId = e?._id
  const statusId = e?.statusId
  const assetId = e?.assetId
  const lang = e?.lang
  const type = e?.type
  const PartiallyFinished = e?.PartiallyFinished
  // console.log(e)
  const workflowId = "6274aa0f-af4d-47ad-a4f0-9e10230c8ef4"
  const obj = {
    operationId,
    statusId,
    assetId,
    workflowId,
    user,
    type,
    PartiallyFinished
  }
  // const _user = {
  //   _id: user.userId,
  //   user: user.firstName + " " + user.lastName
  // }
  // console.log(e)
  // return false
  const result = await allinPut("prepareOpBoard", obj);
  // console.log(result)
  if (result?.success) {
    if (result?.value) {
      return result
    }else {
      if (result?.msgs) {
        // result?.msgs.forEach((el, i) => {
        //   msg("warning", (lang?.[el?.msg] ?? el?.msg), 2)
        // });
        return result
      }else {
        // msg("warning", (lang?.[result?.msg] ?? result?.msg), 2)
        return result
      }
    }
    // if (result?.msg === "notAllowed") {
    // }
  }
  // TODO:
  // - status
  // - board
  // -
  // -
  // -
  // -
  // -
  // -
  // console.log({
  //   operationId,
  //   statusId,
  //   assetId,
  // })
}

const getCalcTimes = async(op, prop, v) => {
  // console.log({
  //   line: "545-worker",
  //   op, prop
  // })
  // console.log(op)
  if (prop === "TakenOverNaDN") {
    const value = op.requiredMaterials.filter((f) => f?.erp_ItemType === "500")
    if (value && value.length > 0) {
      return value?.[0]?.takeOverQuantity
    }else {
      return 0
    }
  }else if (prop === "PRIPRAVLJENA_KOLICINA") {
    if (op.requiredMaterials && op.requiredMaterials.length > 0) {
      return op.requiredMaterials?.[0]?.preparedQuantity ? op.requiredMaterials?.[0]?.preparedQuantity : 0
    }else {
        return 0
    }

    // console.log(op.requiredMaterials)
    // const value =  op.requiredMaterials.filter((f) => f?.erp_ItemType === "500")
    // if (value && value.length > 0) {
    //   return value?.[0]?.preparedQuantity ? value?.[0]?.preparedQuantity : 0
    // }else {
    //     return 0
    // }
  }else if (prop === "NastavitveniPrehodi") {
    const r = await allinOne("getDocumentById", { type: "AssetDef", id: v })
    if (r) {
      return r?.parameters?.assetDef_pruductionThreshold ? r?.parameters?.assetDef_pruductionThreshold : 10
    }else {
      return 10
    }
  }else if (prop === "Stanjestevca_T") {
    const r = await allinOne("getDocumentById", { type: "Asset", id: v })
    if (r) {
      return r?.parameters?.sfp_TotalSteviloKosov ? r?.parameters?.sfp_TotalSteviloKosov : 0
    }else {
      return 0
    }
  }else if (prop === "PrehodiNaUroActual") {
    const r = await allinOne("getDocumentById", { type: "Asset", id: v })
    if (r) {
      return r?.parameters?.sfp_rKosovNaUro ? r?.parameters?.sfp_rKosovNaUro : 0
    }else {
      return 0
    }
  }else if (prop === "PrehodiNaUroActual") {

  }

  return 0
}

const rmValidation = (e, lang) => {
  // console.log(e)
  if (e) {
    const rm = e.filter((f) => !f?.preparedQuantity)
    const rmb = e.filter((f) => f?.preparedQuantity > f?.takeOverQuantity)
    if (rmb.length > 0) {
      // console.log(rmb)
      msg("warning", (lang?.["canNotBeGreaterPQThenTOQ"] ?? "canNotBeGreaterPQThenTOQ"), 2)
      return false
    }
    // console.log(rm)
    if (rm.length > 0) {
      msg("warning", (lang?.["canNotBeEmptyOrNull"] ?? "canNotBeEmptyOrNull"), 2)
      return false
    }else {
      return true
    }
  }else {
    return false
  }
}

const updateRqMaterial = async(e) => {
  const lang = e?.lang
  const result = await allinPut("updateRqMaterial", e)
  // console.log(result)
  if (result.success) {
    msg("success", (lang?.["saved"] ?? "saved"), 2)
  }
}

const removeUsersFromAsset = async (e) => {
  const assetId = e?.assetId
  // console.log(assetId)
  let tempArr = []
  const result = await allinOne("getUsersOnAsset", { assetId })
  for (var i = 0; i < result?.length; i++) {
    tempArr.push(result?.[i])
    await allinPut("removeUserAssetMgmt", { ...result?.[i] })
    await allinPut("removeUserOnAsset", {
      assetId: result?.[i]?.assetId,
      userId: result?.[i]?.userId,
    })
  }
  if (tempArr.length === result?.length) {
    // socket.connect()
    socket.emit("usersOn", {})
    return true
    // console.log(true)
  }
}

export {
  prepareInfo,
  prepareColumns,
  paramDestruct,
  prepareColumn,
  makeAReservation,
  checkEmptyValues,
  updateUserAssetMgmt,
  checkStatus,
  setWfTasks,
  reorder,
  move,
  updateSpecOperation,
  dragLimitCatch,
  statusHandler,
  statusCondition,
  statusName,
  reverseStatusName,
  setNewStatusOp,
  setNewStatusOpV2,
  getCalcTimes,
  rmValidation,
  updateRqMaterial,
  removeUsersFromAsset,
  updateSpecSelectedOperations
}
